import Vue from 'vue'
import Vuex from 'vuex'
import '@mdi/font/css/materialdesignicons.css'
import {pop} from "@/utils/pop";

Vue.use(Vuex)
const update = (commit, key, value) => {
    commit('updateState', {key, value})
}
const updateKey = (commit, property, key, value) => {
    commit('updateStateKey', {property, key, value})
}
const spliceS = (commit, property, idx, del = 0, data = []) => {
    commit('spliceState', {property, idx, del, data})
}

const api = async ({commit, api, method = 'post', triggerLoading = true, errorPop = true, params, successFn, failedFn}) => {
    try {
        // 防止因为loading导致的页面刷新
        if (triggerLoading) commit('updateState', {key: 'loading', value: true})
        let {state, data, err, userInfo} = await fetch(
            api,
            {
                method,
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(params)
            })
            .then(res => res.json())
        if (state) {
            // console.log({userInfo})
            commit('updateState', {key: 'userInfo', value: userInfo})
            return successFn(data, userInfo)
        } else {
            console.log(err)
            errorPop && err && pop({
                text: err
            }, 'error')
            return failedFn(data, userInfo)
        }
    } catch (e) {
        console.log(e)
        failedFn && failedFn()
        errorPop && e && pop({
            color: 'error',
            text: '抱歉，网络有点拥挤，重新搜下试试~'
        }, 'error')
        return false
    } finally {
        commit('updateState', {key: 'loading', value: false})
    }
}

export default new Vuex.Store({
    state: {
        windowSize: {x: 375, y: 800},
        mobile: true,
        loading: false,
        userInfo: {},
        /**
         * todo 此处的api地址应该是通过请求接口获得的短链接地址
         */
        token: '',
        api: process.env.NODE_ENV === "development" ? 'http://localhost:3001/' : 'https://diting-8gbp0u9a8f9494c8-1256676139.ap-guangzhou.app.tcloudbase.com/inquiry-api/',
        categoryMap: {
            symptom: '症状',
            disease: '疾病',
            medicine: '药品',
            syndromeType: '证素',
            syndrome: '证型',
            prescription: '方剂',
            acupuncture: '针灸穴位',
            herb: '中草药',
            medRecipe: '药膳食疗'
        },
        categoryColorMap: {
            symptom: {color: 'red', name: '症状'},
            disease: {color: 'teal', name: '疾病'}
        },
        severities: [
            {value: 0, text: '无', color: 'grey'},
            {value: 0.7, text: '轻', color: 'green lighten-2'},
            {value: 1, text: '中', color: 'primary lighten-2'},
            {value: 1.5, text: '显', color: 'warning lighten-2'},
            {value: 2, text: '重', color: 'red lighten-2'},
        ],
        conversations: [],
    },
    mutations: {
        updateState(state, {key, value}) {
            state[key] = value
        },
        updateStateKey(state, {property, key, value}) {
            Vue.set(property, key, value)
        },
        spliceState(state, {property, idx, del = 0, data = []}) {
            property.splice(idx, del, ...data)
        },
        pushState(state, {property, value}) {
            property.push(value)
        },
        judgeMobile(state, windowSize) {
            state.windowSize = windowSize
            state.mobile = windowSize.x < 1200
        },
    },
    actions: {
        async request({commit, state}, req) {
            const {customApi = null, customParams = null, action, params = {}, fn, fail, triggerLoading = true, errorPop = true} = req
            console.log(req)
            return await api({
                commit,
                errorPop,
                triggerLoading,
                method: 'post',
                api: customApi || state.api + state.token,
                customApi: !!customApi,
                params: customParams || {action, params},
                successFn: (data) => {
                    fn && fn(data)
                    return data
                },
                failedFn: (data) => {
                    fail && fail(data)
                }
            })
        },
    },
    modules: {}
})
